<template>
  <v-card class="ma-5">
    <v-card-title class="primary justify-center white--text">
      Отчет по сварщику
    </v-card-title>
    <div v-if="loaded" class="pb-1">
      <v-container  class="ma-4 bordered" style="width: auto;">
        <div class="title text-center">Общаяя статистика</div>
        <v-simple-table class="text-center">
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  Выполнено сварных швов(техкарт)
                </th>
                <th>
                  Суммарное время выполнения операций
                </th>
                <th>
                  Суммарное время сварки
                </th>
                <th>
                  Среднее критическое сводное отклонение от режима
                </th>
                <th>
                  Среднее общее сводное отклонение от режима
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{mainStatistic.cardsCount}}</td>
                <td>{{secToHoursTime(mainStatistic.sumTime)}}</td>
                <td>{{secToHoursTime(mainStatistic.sumWeldTime/1000)}}</td>
                <td>{{
                  toPercentString(
                    mainStatistic.sumDevCrit,
                    mainStatistic.sumWeldTime
                  )}}</td>
                <td>{{
                  toPercentString(
                    mainStatistic.sumDevCommon,
                    mainStatistic.sumWeldTime
                  )}}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-container>
      <v-container  class="ma-4 bordered" style="width: auto;">
        <div class="title text-center">Выполненные карты</div>
        <v-simple-table class="text-center">
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  ID/Название
                </th>
                <th>
                  Начата
                </th>
                <th>
                  Время выполнения
                </th>
                <th>
                  Время сварки
                </th>
                <th>
                  Свод. крит. откл.
                </th>
                <th>
                  Свод. общ. откл.
                </th>
                <th>
                  Способ сварки
                </th>
                <th>
                  Вид элементов
                </th>
                <th>
                  D1
                </th>
                <th>
                  D2
                </th>
                <th>
                  S1
                </th>
                <th>
                  S2
                </th>
                <th>
                  Шов
                </th>
                <th>
                  Соединение
                </th>
                <th>
                  Материал
                </th>
                <th>
                  Св. материал
                </th>
                <th>
                  Положение
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="card in welderCards" :key="card.id" @click="goViewProcCard(card.id)">
                <td>
                  {{ card.card.name }}
                </td>
                <td>{{ secToDate(card.startedAt) }}<br>{{secToTime(card.startedAt)}}</td>
                <td>{{ secToHoursTime(card.finishedAt - card.startedAt) }}</td>
                <td>{{ secToHoursTime(card.weldTime / 1000) }}</td>
                <td>{{ toPercentString(card.devCrit, card.weldTime) }}</td>
                <td>{{ toPercentString(card.devCommon, card.weldTime) }}</td>
                <td>{{ card.card.weldMethod }}</td>
                <td>{{ card.card.elementsType }}</td>
                <td>{{ card.card.d1 }}</td>
                <td>{{ card.card.d2 }}</td>
                <td>{{ card.card.s1 }}</td>
                <td>{{ card.card.s2 }}</td>
                <td>{{ card.card.seamType }}</td>
                <td>{{ card.card.jointType }}</td>
                <td>{{ card.card.baseMaterial }}</td>
                <td>{{ card.card.weldMaterial }}</td>
                <td>{{ card.card.weldPosition }}</td>
              </tr>
              <tr v-if="welderCards.length === 0"><td colspan="14">Не найдено ни одной тех. карты</td></tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination class="mt-3" v-model="page" :length="pagesTotal"/>
      </v-container>
    </div>
  </v-card>
</template>

<script>
import { toPercentString } from '@/libs/common.js'
import { secToDate, secToTime, secToHoursTime } from '@/libs/time.js'

export default {
  data: () => ({
    loaded: true,
    toPercentString,
    secToDate, secToTime, secToHoursTime,
    mainStatistic: {},
    welderCards: [],
    perPage: 15,
    pagesTotal: 0,
    page: 1,
  }),
  methods: {
    goViewProcCard(id) {
      this.$router.push({
        path: '/manufacture/process-card/view',
        query: { id: id }
      })
    },
    getWelderName(welderId) {
      let w = this.welders.find(w => Number(w.userId) === Number(welderId))
      if (w) {
        return w.firstname + ' ' + w.middlename + ' ' + w.lastname
      } else {
        return '?'
      }
    },
    getReport() {
      let url = '/manufacture/report/staff/view'
      this.$axios.get(url, {
        params: {
          ...this.$route.query,
          page: this.page,
          pageSize: this.perPage,
       }
      }).then(r => {
        this.welderCards = r.data.items.slice()
        this.mainStatistic = r.data.mainStatistic
        this.pagesTotal = r.data.pagination.pagesTotal
      }).catch(() => {}).finally(() => { this.loaded = true })
    },
  },
  watch: {
    page(newPage) {
      this.getReport()
    }
  },
  mounted: function() {
    this.getReport()
  }
}
</script>


<style>
  .text-center th {
    text-align: center !important;
  }
  .bordered {
    border-style: solid;
    border-width: thin;
  }
</style>
